var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-3"},[_c('v-tabs',{attrs:{"bg-color":"deep-purple-accent-4","centered":"","stacked":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"value":"tab-1"}},[_c('v-icon',[_vm._v("mdi-launch")]),_vm._v(" Lançamentos ")],1),_c('v-tab',{attrs:{"value":"tab-2"}},[_c('v-icon',[_vm._v("mdi-view-dashboard")]),_vm._v(" Dashboard ")],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[(_vm.loading.list)?_c('base-loading'):_c('crud-list',{attrs:{"headers":_vm.headers,"actions":_vm.actions,"slots":[
          'item.total',
          'item.debito',
          'item.dataAtendimento',
          'item.combustivel',
          'item.alimentacao',
          'item.hospedagem',
          'item.pedagio',
        ]},scopedSlots:_vm._u([(
            _vm.$user.get().role === 'consultor' || _vm.$user.get().role === 'admin'
          )?{key:"btnCadastro",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"col-xs-2 py-md-0",attrs:{"cols":"12","sm":"6","md":"auto"}},[_c('v-btn',{staticClass:"rounded-lg",attrs:{"color":"green darken-1","dark":"","loading":_vm.loadingSheet},on:{"click":_vm.exportSheet}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-file-excel")]),_c('span',{staticStyle:{"font-size":"16px","font-weight":"600"}},[_vm._v("Exportar")])],1)],1),_c('v-col',{staticClass:"col-xs-2 py-md-0",attrs:{"cols":"12","sm":"6","md":"auto"}},[_c('router-link',{attrs:{"to":`financeiro/create`}},[_c('v-btn',{attrs:{"color":"success"}},[_vm._v(" Novo lançamento ")])],1)],1)],1)]},proxy:true}:null,{key:`item.total`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.total ? 'R$ ' + _vm.currencyFormatter(item.total) : ' - ')+" ")]}},{key:`item.debito`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.debito ? 'R$ ' + _vm.currencyFormatter(item.debito) : ' - ')+" ")]}},{key:`item.dataAtendimento`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.dataAtendimento ? _vm.moment(item.dataAtendimento).format('DD/MM/YYYY') : ' - ')+" ")]}},{key:`item.combustivel`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.combustivel ? 'R$ ' + _vm.currencyFormatter(item.combustivel) : ' - ')+" ")]}},{key:`item.alimentacao`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.alimentacao ? 'R$ ' + _vm.currencyFormatter(item.alimentacao) : ' - ')+" ")]}},{key:`item.hospedagem`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.hospedagem ? 'R$ ' + _vm.currencyFormatter(item.hospedagem) : ' - ')+" ")]}},{key:`item.pedagio`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.pedagio ? 'R$ ' + _vm.currencyFormatter(item.pedagio) : ' - ')+" ")]}}],null,true),model:{value:(_vm.list),callback:function ($$v) {_vm.list=$$v},expression:"list"}})],1),_c('v-tab-item',[_c('Dashboard')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }